import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/layout';
import SEO from '../components/seo';
import Img from 'gatsby-image';
import { AtSign, Linkedin, GitHub, Gitlab } from 'react-feather';
import styles from '../styles/bio.module.css';

export const query = graphql`
	query ProfilePageTemplate($id: ID!) {
		cms {
			Bio(where: { id: $id }) {
				name
				title
				id
				email
				description
				linkedin
				github
				gitlab
				image {
					publicUrl
					gatsbyFile {
						childImageSharp {
							fluid(maxWidth: 700, maxHeight: 700) {
								...GatsbyImageSharpFluid
							}
						}
					}
				}
			}
			allVolunteers(where: { volunteers_some: { id: $id } }, orderBy: "year_DESC") {
				role
				year
			}
		}
	}
`;

export default ({ data }) => {
	return (
		<Layout>
			<SEO title={data.cms.Bio.name} keywords={['bio', 'biography', 'profile', data.cms.Bio.name]} />
			<article>
				<section className='flex justify-stretch items-stretch flex-wrap paper'>
					<figure id={styles.bioFigure} className='top-left'>
						{data.cms.Bio.image ? (
							<Img fluid={data.cms.Bio.image.gatsbyFile.childImageSharp.fluid} />
						) : null}
						{data.cms.Bio.name ? (
							<figcaption className='text-xl font-bold text-center tracking-wider m-4'>
								{data.cms.Bio.name}
							</figcaption>
						) : null}
					</figure>
					<div id={styles.bioProfile}>
						<h2>Profile</h2>
						<dl className='flex flex-wrap'>
							{data.cms.allVolunteers.length > 0 ? (
								<div>
									<dt>Volunteer Experience</dt>
									{data.cms.allVolunteers.map(({ role, year }) => (
										<dd key={year}>{`${role} ${year}`}</dd>
									))}
								</div>
							) : null}
							{data.cms.Bio.title ? (
								<div>
									<dt>Title</dt>
									<dd>{data.cms.Bio.title}</dd>
								</div>
							) : null}
						</dl>

						<h2 className='mt-8'>Contact</h2>
						<address className='flex flex-wrap'>
							{data.cms.Bio.email ? (
								<a href={`mailto:${data.cms.Bio.email}`}>
									<AtSign />
									{data.cms.Bio.email}
								</a>
							) : null}
							{data.cms.Bio.linkedin ? (
								<a href={data.cms.Bio.linkedin}>
									<Linkedin />
									LinkedIn
								</a>
							) : null}
							{data.cms.Bio.github ? (
								<a href={data.cms.Bio.github}>
									<GitHub />
									Github
								</a>
							) : null}
							{data.cms.Bio.gitlab ? (
								<a href={data.cms.Bio.gitlab}>
									<Gitlab />
									Gitlab
								</a>
							) : null}
						</address>
					</div>
				</section>
				{data.cms.Bio.description ? (
					<section
						className='paper p-4 rich-text bg-white'
						dangerouslySetInnerHTML={{ __html: data.cms.Bio.description }}
					/>
				) : null}
			</article>
		</Layout>
	);
};
